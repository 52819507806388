import React from 'react';
import Footer from '../components/Footer';
import factorybg from '../images/factory1.2.jpeg'
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../styles/Contact.css';


const Contact = () => {
  return (
    <>
    <div > <img className='bgimg' src={factorybg} alt='background' />
    </div>
    <div className='contact-wrapper'>
      <Container>
        <Row className='justify-content-center'>
          <Col sm={5} className="contact-wrapper-col m-3">
                <h4> 
                  <span className="fa-solid fa-location-dot"/>Location
                </h4>
                <p> Unit 1 : <br/>
                  Vavdi Village Road, Gondal Road, N.H., Survey No. 46,<br/>
                  Vavdi, Rajkot, Gujarat, 360004
                </p> 
                <p> Unit 2 : <br/>
                  Survey No. 247/1 Paiki 2, Plot No 1 & 2 Shed,<br/>
                  Wakaner Kuvadva Highway, Village Jiyana <br/>
                  District Rajkot, Gujarat, 360023
                </p> 
          </Col>
          <Col style={{ backgroundColor: 'green'}} sm={4} className="contact-wrapper-col m-3">
                <h4>
                  <span className="fa-solid fa-comments"/>Get In Touch
                </h4> 
                <p>
                  <span className="fa-solid fa-envelope" /> 
                  <Link to="mailto: samrathydraulics@gmail.com" style={{ textDecoration:"none", color:'white'}}> samrathydraulics@gmail.com </Link>
                </p> 
                <p>
                  <span className="fa-brands fa-square-whatsapp"/> 
                  <Link to="tel: +91 8866220999" style={{ textDecoration:"none", color:'white'}}> +91 8866220999 </Link> 
                </p>
                <p>
                  <span className="fa-solid fa-phone" /> 
                  <Link to="tel: +91 9824396880" style={{ textDecoration:"none", color:'white'}}> +91 9824396880 </Link> 
                </p>
            </Col>
        </Row>
        <Row>
          <Col sm={12}>
          <div style={{hight:"100%", Width:'100%'}}>
      <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118028.35157149594!2d70.79762850173816!3d22.41449344625099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959b1e93d06e1ed%3A0x61501d949ae4e62!2sSamrat%20Hydraulics!5e0!3m2!1sen!2sau!4v1706063668167!5m2!1sen!2sau" width="100%" height="450vh" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
          </Col>
        </Row>
     
      </Container>
      <div className=''>
        <Footer/>
      </div>
    </div>
    </>
  );
}

export default Contact;