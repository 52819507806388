import React from 'react';
import { Outlet, Link, useLocation } from "react-router-dom";
import BurgerMenu from './BurgerMenu';
import  '../styles/Nav.css';
import logo from "../images/logo-removebg-preview.png";

const Nav = () => {
  React.useEffect(() => {
    const menuWrap = document.querySelector(".bm-menu-wrap");
    if (menuWrap) {
      menuWrap.setAttribute("aria-hidden", "true");
    }
  }, []);
  const location = useLocation();
  return (
    <>
      <nav>
        <div className='nav-wrapper'>
        <ul className='nav-links'>
          <li>
          <img src={logo} alt="logo"/>
          </li>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link to="/">Home</Link>
          </li>
          <li className={location.pathname === '/Product' ? 'active' : ''}>
            <Link to="/Product">Our Product</Link>
          </li>
          <li className={location.pathname === '/about' ? 'active' : ''}>
            <Link to="/about">About Us</Link>
          </li>
          <li className={location.pathname === '/contact' ? 'active' : ''}>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        </div>
        <div className="mobile_header">
        <img src={logo} alt="logo"/>
                <BurgerMenu/>
            </div>
  
      </nav>

      <Outlet />
    </>
  )
};

export default Nav;