import React from 'react';
import Homepage from './pages/Homepage';
import About from "./pages/About";
import Contact from './pages/Contact';
import Product from './pages/Product';
import Nav from "./components/Nav";
import {BrowserRouter, Routes, Route} from "react-router-dom";



function App() {
  return (
    <div>     
      <BrowserRouter>
            <Routes>
                <Route path="/" element={<Nav />}>
                <Route index element={<Homepage />} />
                <Route path="/product" element={<Product />} />  
                <Route path="/about" element={<About />} />  
                <Route path="/contact" element={<Contact />} />  
                </Route> 
            </Routes>
      </BrowserRouter> 
    </div>
  );
}
export default App;


