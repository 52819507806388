import React from 'react';
import PropTypes from 'prop-types';
//import "../styles/BackGroundBox.css";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col} from 'react-bootstrap'; 

const ProductList = ({ headingText, cardData}) => {
    console.log(cardData);
    return (
        <Container>
            <Row>
            <u> <h3 style={{paddingTop:"20px"}}> {headingText}</h3> </u>
            {cardData.map((card, index) => (
                <Col key={index} sm={4} className="d-flex justify-content-center p-3">
                <Card bg={card.Color} border={card.Color} style={{ width: '18rem'}}>
                    <Card.Img variant="top" src={card.imgSrc} alt={card.imgAlt} style={{height: '19rem'}}/>
                    <Card.Body className="d-flex flex-column align-items-center" >
                    <Card.Title className="text-center" style={{color:'whitesmoke'}}>{card.labelText}</Card.Title>
                    </Card.Body>
                </Card>
                </Col>
                ))}
            </Row>
        </Container>
    );
};
  
ProductList.propTypes = {
    headingText: PropTypes.string.isRequired,
    cardData: PropTypes.arrayOf(
      PropTypes.shape({
        labelText: PropTypes.string.isRequired,
        imgSrc: PropTypes.string.isRequired,
        imgAlt: PropTypes.string.isRequired,
        Color: PropTypes.string.isRequired,
      })
    ).isRequired,
  };
    
  export default ProductList;