import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/HomePage.css'; 
import Footer from '../components/Footer';
import BackGroundImg from '../images/SamratHydraulics.png';
import Slider from '../components/Carousel';


const Homepage = () => {
  return (
    <>
    <div>
    <img src={BackGroundImg} alt="background img" width="100%"/>
    </div>
    <div>
      <Slider/>
    </div>
    <div>
      <Container fluid className='mission-box'>
        <Row >
          <Col>
              <p>"Delivering Low-Cost High-Efficiency Hydraulic Systems" 
              </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="home-container">
        <Row className='bg-success'>
            <Col sm={4} className='d-flex justify-content-center'>
            <div className="stat-box">
            <div className="symbol"><span className="fa-solid fa-cubes"></span></div>
            <div className="count">100+</div>
            <div className="text"><p>Range of Products</p></div>
            </div>
          </Col>
          <Col sm={4} className='d-flex justify-content-center'>
          <div className="stat-box">
            <div className="symbol"><span className='fa-regular fa-face-laugh-beam'></span></div>
            <div className="count"> 1000+</div>
            <div className="text"><p>Happy Customers</p></div>
            </div>
          </Col>
          <Col sm={4} className='d-flex justify-content-center'>
          <div className="stat-box">
            <div className="symbol "> <span className='fa-solid fa-business-time'></span></div>
            <div className="count">25+</div>
            <div className="text"><p>Years of Experience</p></div>
            </div> 
          </Col>
        </Row>
      </Container>
    </div>
    <div>
      <Footer/>
    </div>
    </>
  );
}

export default Homepage;