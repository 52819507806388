import React, { useState } from 'react';
import {Link} from "react-router-dom";
import '../styles/Burger.css';
import { slide as Menu } from 'react-burger-menu';


const BurgerMenu = () => {
    const [isOpen, setOpen] = useState(false)

    const handleIsOpen = () => {
        setOpen(!isOpen)
    }

    const closeSideBar = () => {
        setOpen(false)
    }

    return(
    <div> 
        <Menu
        isOpen={isOpen}
        onOpen={handleIsOpen}
        onClose={handleIsOpen} right>
        <ul className="burger-links">
            <li>
                <Link onClick={closeSideBar} className="navbar-link bm-item" to="/">Home</Link>
            </li>
                <li>
                    <Link onClick={closeSideBar} className="navbar-link bm-item" to="/product">Our Products</Link>
                </li>
                <li>
                    <Link onClick={closeSideBar} className="navbar-link bm-item" to="/about">About Us</Link>
                </li>
                <li>
                    <Link onClick={closeSideBar} className="navbar-link bm-item" to="/contact">Contact Us</Link>
                </li>
        </ul>
        </Menu>
    </div>
    );
}
export default BurgerMenu;