import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import Footer from '../components/Footer'; 
import '../styles/About.css';
import img1 from '../images/4.1.jpeg';
import img2 from '../images/5.1.jpeg';

const About = () => {
   
  return (
        <>
        <div>
        <Container>
                <Row className='bg-success p-3' style={{color:'whitesmoke'}} >
                    <Col sm='4'>
                        <h1>Our Mission </h1>
                    </Col>
                    <Col sm='8'>
                        <p style={{fontSize: '18px', textAlign:'justify'}}>"To deliver Low-Cost High-Efficiency Hydraulic Systems, embodying our values of Business Ethics, Client Focus, Technical Excellence, Quality, Efficiency and Innovation. Our commitment is to provide reliable solutions that exceed expectations and drive success for our clients."
                        </p>    
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='p-3'>
                    <Col sm='6' style={{ textAlign: 'justify'}}>
                        <h1 style={{color:'#ff4444'}}>Who We Are ?</h1>
                        <p>
                            Established in 1995, Samrat Hydraulics is a distinguished name in the hydraulic industry, representing a legacy of reliability, innovation and a commitment to excellence at an affordable price. With over two decades of experience, we have grown to become a trusted name for clients seeking cutting-edge hydraulic solutions. Founded on principles of integrity and precision, Samrat Hydraulics has evolved into a beacon of trust since its inception in 1995. Our enduring dedication has propelled us to new heights, making us a respected and reliable presence in the hydraulics sector among suppliers, manufacturers, and exporters. At Samrat Hydraulics, our state-of-the-art infrastructure is equipped with cutting-edge machinery and equipment, ensuring the quality of our products.
                        </p> 
                    </Col>
                    <Col sm='6'>
                        <div>
                            <img src={img2} alt="background img" width="100%" height="330em"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm='6'>
                        <div>
                            <img src={img1} alt="background img" width="100%" height="350em"/>
                        </div>
                    </Col>
                    <Col sm='6' style={{ textAlign: 'justify' }}>
                        <h1 style={{color:'#ff4444'}}>What We Do ?</h1>
                        <p>
                            At Samrat Hydraulics, we go beyond manufacturing – we are architects of fluid power solutions. Our expertise spans a comprehensive range of hydraulic components like control valves, pressure relief valves, prefill valves, check valves, and accessories, ensuring that each product we deliver is a testament to quality, durability, and innovation. Our hydraulic components find applications across a spectrum of industries, from agriculture contributing to efficiency of farming machinery, to manufacturing sector, providing cutting-edge hydraulic technology to enhance production processes, to transportation industry, where our hydraulic components contribute to the seamless operation. Moreover, our products are utilized in diverse sectors, showcasing their versatility and adaptability to meet the hydraulic power needs of various industries.
                        </p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className='bg-success'> <h1 id='header'>Our Values</h1>
                    <Col sm={4} className='d-flex justify-content-center'> 
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-users-between-lines"></i>
                    </div>
                    <div className='value-heading'>
                        Client Focus
                    </div>
                    <div className='value-text'>
                       <p> Putting our clients at the center, understanding their needs, and providing exceptional customer experiences. </p>
                    </div>
                    </div>
                    </Col>
                    <Col sm={4} className='d-flex justify-content-center'>
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-gears"></i>
                    </div>
                    <div className='value-heading'>
                        Technical Excellence
                    </div>
                    <div className='value-text'>
                       <p> Pursuing the highest standards of technical expertise to deliver quality products and services.</p>
                    </div>
                    </div>
                    </Col>
                    <Col sm={4} className='d-flex justify-content-center'>
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-scale-balanced"></i>
                    </div>
                    <div className='value-heading'>
                        Business Ethics
                    </div>
                    <div className='value-text'>
                       <p> Conducting business with integrity, honesty, and ethical principles in all our interactions. </p>
                    </div>
                    </div>
                    </Col>
                </Row>
                <Row className='bg-success'>
                    <Col sm={4} className='d-flex justify-content-center'>
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-lightbulb"></i>
                    </div>
                    <div className='value-heading'>
                        Innovation
                    </div>
                    <div className='value-text'>
                        <p> Embracing creativity and staying ahead through continuous exploration of new technologies and ideas.</p>
                    </div>
                    </div>
                    </Col>
                    <Col sm={4} className='d-flex justify-content-center'>
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-arrow-up-right-dots"></i>
                    </div>
                    <div className='value-heading'>
                        Efficiency
                    </div>
                    <div className='value-text'>
                       <p> Optimizing processes to deliver maximum value with streamlined and effective operations. </p> 
                    </div>
                    </div>
                    </Col>
                    <Col sm={4} className='d-flex justify-content-center'>
                    <div className='value-wrapper'>
                    <div className='value-symbol'>
                        <i class="fa-solid fa-ranking-star"></i>
                    </div>
                    <div className='value-heading'>
                        Quality 
                    </div>
                    <div className='value-text'>
                        <p>Unwavering commitment to excellence, ensuring top-notch product quality through rigorous testing. </p> 
                    </div>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div>
        <Footer/>
        </div>    
    </> 
  );
}

export default About;