import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "../images/logo-removebg-preview.png";
import '../styles/Footer.css';
<Link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"/>

const Footer = () => {
    return (
        <footer>
          <div className='footer-wrapper'>
          <Container fluid>
            <Row className='p-3'>
               <Col sm={{ span: 4, order: 2}}>
                <ul className='footer-links'>
                  <li>
                    <h4>Links</h4>
                    <Link to="/">Home</Link>
                    <Link to="/product">Our Products</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/contact">Contact Us</Link>
                  </li>  
                </ul>
              </Col>
              <Col sm={{ span: 4, order: 3}} >
                <h4>Contact Us</h4>
                <p>
                  <span className="fa-solid fa-phone" style={{paddingRight: "4px"}}/> 
                  <Link to="tel: +91 8866220999" style={{ textDecoration:"none", color:'black'}}> +91 8866220999 </Link> /<Link to="tel: +91 9824396880" style={{ textDecoration:"none", color:'black'}}> +91 9824396880 </Link>
                </p>
                <p>
                  <span className="fa-solid fa-envelope" style={{paddingRight: "5px"}}/> <Link to="mailto: samrathydraulics@gmail.com" style={{ textDecoration:"none", color:'black'}}> samrathydraulics@gmail.com </Link> 
                </p>
                <p>
                  <span className="fa-solid fa-location-dot" style={{paddingRight: "5px"}}/> Survey No. 247/1 Paiki 2, Plot No. 1 & 2 Shed,<br />
                  <span style={{paddingRight: "20px"}}/> Wakaner Kuvadva Highway, Village Jiyana <br/>
                  <span style={{paddingRight: "20px"}}/> District Rajkot - 360023, Gujarat, India
                </p> 
              </Col>
              <Col sm={{ span: 4, order: 1}} >
              <img src={logo} alt="logo-removebg-preview"/>
                <p>Low-Cost High-Efficiency Hydraulic Systems</p>
                <div className="social">
                  <Link to="https://www.facebook.com/" target="_blank" style={{ color:'black'}}><span className='fa-brands fa-facebook-f'></span></Link>
                  <Link to="https://www.instagram.com/" target="_blank" style={{ color:'black'}}><span className="fa-brands fa-linkedin-in"></span></Link> 
                  <Link to="https://twitter.com/login" target="_blank" style={{ color:'black'}}><span className="fa-brands fa-x-twitter"></span></Link>
                </div>
              </Col>
            </Row>
            </Container>
            <div className='line'></div>
            <Container>
            <Row>
              <Col sm={4}>
              <p>© Samrat Hydraulics Pvt. Ltd. All Rights reserved.</p>
              </Col>
              <Col>
              </Col>
              <Col sm={4}>
              <p> Developed and Managed By Nehali Hingrajiya</p>
              </Col>
            </Row>
          </Container>
          </div>
        </footer>
    );
};

export default Footer;