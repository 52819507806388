import React from 'react';
import Footer from '../components/Footer';
import ProductList from '../components/ProductList';
import checkvalve1 from "../images/Checkvalve/pocv.jpeg";
import checkvalve2 from "../images/Checkvalve/pocvs.jpeg";
import checkvalve3 from "../images/Checkvalve/smcv.jpeg";
import checkvalve4 from "../images/Checkvalve/imcv.jpeg";
import pressurerelief1 from "../images/pressurerelief/prvsm.jpeg"; 
import pressurerelief2 from "../images/pressurerelief/prvtm.jpeg"; 
import pressurerelief3 from "../images/pressurerelief/prvc.jpeg"; 
import pressurerelief4 from "../images/pressurerelief/prvs.jpeg"; 
import directioncontrol1 from "../images/directioncontrol/dc3p.jpeg";
import directioncontrol2 from "../images/directioncontrol/dc4p.jpeg";
import directioncontrol3 from "../images/directioncontrol/dcsm.jpeg";
import directioncontrol4 from "../images/directioncontrol/dctm.jpeg";
import accessories1 from "../images/accessories/ha.jpeg";
import accessories2 from "../images/accessories/hhn.jpeg";
import accessories3 from "../images/accessories/hn.jpeg";
import accessories4 from "../images/accessories/wn.jpeg";
import prefill1 from "../images/prefill/prefill.jpeg";
import prefill2 from "../images/prefill/prefills.png";
import manifold1 from "../images/manifold/mfb.jpeg";
import manifold2 from "../images/manifold/mfct.png"; 
import subplates1 from "../images/subplates/s3.jpeg";
import subplates2 from "../images/subplates/s4.jpeg";
import pressureisoleter from "../images/pressureisoleter/pgiv.jpeg";
import pressurecontrol from "../images/pressurecontrol/pcm.png"; 

const Product = () => {
  const pressureisoleterrow = [
    {
      labelText: 'Pressure Guage Isolator Valve Single Station',
      imgSrc: pressureisoleter,
      imgAlt: 'Pressure Isoleter',
      Color: 'success',
    }
];
const pressurecontrolrow = [
  {
    labelText: 'Pressure Control Module',
    imgSrc: pressurecontrol,
    imgAlt: 'Pressure Control',
    Color: 'success',
  }
];
const manifoldrow = [
    {
      labelText: 'Manifold Block',
      imgSrc: manifold1,
      imgAlt: 'Manifold',
      Color: 'success',
    },
    {
      labelText: 'Manifold Block With Cartridge',
      imgSrc: manifold2,
      imgAlt: 'Manifold',
      Color: 'danger',
    }
];
const subplatesrow = [
  {
    labelText: 'Subplates',
    imgSrc: subplates1,
    imgAlt: 'Sub Plate',
    Color: 'success',
  },
  {
    labelText: 'Subplates',
    imgSrc: subplates2,
    imgAlt: 'Sub Plate',
    Color: 'danger',
  }
];
const prefillrow = [
  {
    labelText: 'Prefill and Exhaust Valve',
    imgSrc: prefill1,
    imgAlt: 'Prefill',
    Color: 'success',
  },
  {
      labelText: 'Prefill and Exhaust Valves',
      imgSrc: prefill2,
      imgAlt: 'Prefill',
      Color: 'danger',
    }
];
const accessoriesrow = [
  {
    labelText: 'Hex Adapter',
    imgSrc: accessories1,
    imgAlt: 'Accessories',
    Color: 'success',
  },
  {
    labelText: 'Hex Half Adapter',
    imgSrc: accessories2,
    imgAlt: 'Accessories',
    Color: 'danger',
  },
  {
    labelText: 'Hex Nut',
    imgSrc: accessories3,
    imgAlt: 'Accessories',
    Color: 'warning',
  },
  {
    labelText: 'Weid Nipple',
    imgSrc: accessories4,
    imgAlt: 'Accessories',
    Color: 'success',
  }
];

const directioncontrolrow = [
  {
    labelText: 'Directon Control Valve With Pressure Relief 3 Port',
    imgSrc: directioncontrol1,
    imgAlt: 'Direction Control',
    Color: 'success',
  },
  {
    labelText: 'Directon Control Valve With Pressure Relief 4 Port',
    imgSrc: directioncontrol2,
    imgAlt: 'Direction Control',
    Color: 'danger',
  },
  {
    labelText: 'Directon Control Valve Subplate Mounting',
    imgSrc: directioncontrol3,
    imgAlt: 'Direction Control',
    Color: 'warning',
  },
  {
    labelText: 'Directon Control Valve Threaded Mounting',
    imgSrc: directioncontrol4,
    imgAlt: 'Direction Control',
    Color: 'success',
  }
];
const pressurereliefrow = [
  {
    labelText: 'Pressure Relief Valve Subplate Mounting',
    imgSrc: pressurerelief1,
    imgAlt: 'Pressure Relief',
    Color: 'success',
  },
  {
    labelText: 'Pressure Relief Valve Threaded Mounting',
    imgSrc: pressurerelief2,
    imgAlt: 'Pressure Relief',
    Color: 'danger',
  },
  {
    labelText: 'Pressure Relief Valve Cartridge',
    imgSrc: pressurerelief3,
    imgAlt: 'Pressure Relief',
    Color: 'warning',
  },
  {
    labelText: 'Pressure Relief Valve Screw Type Cartridge',
    imgSrc: pressurerelief4,
    imgAlt: 'Pressure Relief',
    Color: 'success',
  }
];
const checkvalverow = [
  {
    labelText: 'Pilot Operated Check Valve',
    imgSrc: checkvalve1,
    imgAlt: 'Check Valve',
    Color: 'success',
  },
  {
    labelText: 'Pilot Operated Check Valves',
    imgSrc: checkvalve2,
    imgAlt: 'Check Valve',
    Color: 'danger',
  },
  {
    labelText: 'Subplate Mounting Check Valve',
    imgSrc: checkvalve3,
    imgAlt: 'Check Valve',
    Color: 'warning',
  },
  {
    labelText: 'Inline Mounting Check Valve',
    imgSrc: checkvalve4,
    imgAlt: 'Check Valve',
    Color: 'success',
  }
];


  return (
    <>

    <div>
    <ProductList headingText="Direction Control Valve" cardData={directioncontrolrow} />
    <ProductList headingText="Check Valve" cardData={checkvalverow} />
    <ProductList headingText="Prefill Valve" cardData={prefillrow} />
    <ProductList headingText="Pressure Relief Valve" cardData={pressurereliefrow} />
    <ProductList headingText="Manifold" cardData={manifoldrow} />
    <ProductList headingText="Pressure Control Valve" cardData={pressurecontrolrow} />
    <ProductList headingText="Gauge Isolator Valve" cardData={pressureisoleterrow} />
    <ProductList headingText="Subplate" cardData={subplatesrow} />
    <ProductList headingText="Accessories" cardData={accessoriesrow} />
    </div>
      <div className=''>
        <Footer/>
      </div>
    </>
  );
}

export default Product;