import '../styles/Carousel.css';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import directioncontrol from "../images/directioncontrol/dc4p.jpeg";
import checkvalve1 from "../images/Checkvalve/pocv.jpeg";
import checkvalve2 from "../images/Checkvalve/imcv.jpeg";
import prefill from "../images/prefill/prefills.png";
import pressurerelief1 from "../images/pressurerelief/prvtm.jpeg"; 
import pressurerelief2 from "../images/pressurerelief/prvc.jpeg"; 
import manifold from "../images/manifold/mfb.jpeg";
import subplates from "../images/subplates/s4.jpeg";
import accessories from "../images/accessories/ha.jpeg";


const Carouselproduct = () => {
  // Define your product data
  const products = [
    { id: 1, imgUrl: directioncontrol, name: 'Direction Control' },
    { id: 2, imgUrl: checkvalve1, name: 'Check Valve' },
    { id: 3, imgUrl: checkvalve2, name: 'Check Valve' },
    { id: 4, imgUrl: prefill, name: 'Prefill Valve' },
    { id: 5, imgUrl: pressurerelief1, name: 'Pressure Relief' },
    { id: 6, imgUrl: pressurerelief2, name: 'Pressure Relief' },
    { id: 7, imgUrl: manifold, name: 'Manifold' },
    { id: 8, imgUrl: subplates, name: 'Subplates' },
    { id: 9, imgUrl: accessories, name: 'Accessories' },
    // Add more products as needed
  ];

  // State to track the index of the first visible product
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const slider = document.querySelector(".product-list");
      slider.classList.add("slide");
      setTimeout(() => {
        setStartIndex((prevIndex) => (prevIndex + 3 >= products.length ? 0 : prevIndex + 1));
        slider.classList.remove("slide");
      }, 1800); // Wait for the sliding animation to complete before changing products
    }, 5000); // Move to the next set of products every 5 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [products.length]);



  // Function to handle moving to the previous set of products
  const prevSlide = () => {
    setStartIndex((prevIndex) => (prevIndex === 0 ? products.length - 3 : prevIndex - 3));
  };

  // Function to handle moving to the next set of products
  const nextSlide = () => {
    setStartIndex((prevIndex) => (prevIndex + 3 >= products.length ? 0 : prevIndex + 3));
  };

  return (
    <div className="product-slider">
      <div className="product-list">
        <div></div>
        {products.slice(startIndex, startIndex + 3).map((product) => (
          <div key={product.id} className="product-item">
            <img src={product.imgUrl} alt={product.name} />
            <h3>{product.name}</h3>
          </div>
        ))}
      </div>
      <div className="product-link">
        <Link to="/product" className="product-link-text">All Products</Link>
      </div>
      <button className="prev-btn" onClick={prevSlide}><i className="fa-sharp fa-solid fa-arrow-left"></i></button>
      <button className="next-btn" onClick={nextSlide}><i className="fa-sharp fa-solid fa-arrow-right"></i></button>
    </div>
  );
};

export default Carouselproduct;
